import {barnManagerModule} from '../index.module';
import moment from 'moment';
import * as angular from 'angular';
import {convert, DateTimeFormatter, LocalDate, LocalDateTime, LocalTime, nativeJs} from '@js-joda/core';
import {find} from 'lodash';

barnManagerModule.factory('eventMapper', EventMapper);

EventMapper.$inject = ['DENTIST', 'FARRIER', 'VET', 'Event', 'Vendor', 'EventTypes'];
function EventMapper(
  DENTIST,
  FARRIER,
  VET,
  Event,
  Vendor,
  EventTypes
) {

  const DENTIST_APPOINTMENT = 'DENTIST_APPOINTMENT';
  const FARRIER_APPOINTMENT = 'FARRIER_APPOINTMENT';
  const VET_APPOINTMENT = 'VET_APPOINTMENT';

  return {
    makeEmptyEvent: makeEmptyEvent,
    eventToPayload: eventToPayload,
    eventFromPayload: eventFromPayload
  };

  function makeEmptyEvent(barnId: number, hasNoEventsFullPermissionAndHasLessonsFullPermission: boolean) {
    const payload = {
      id: 0,
      tenantEnvironmentId: barnId,
      allDay: 0,
      allHorsesAccess: false,
      allTeamMembersNotifications: false,
      archived: 0,
      compound: null,
      endOn: null,
      endTimeWall: null,
      ends: null,
      eventType: hasNoEventsFullPermissionAndHasLessonsFullPermission ? EventTypes.LESSON.value : EventTypes.VET.value,
      excludedDays: null,
      frequencyNumber: null,
      medication: null,
      notes: null,
      repeat: 0,
      repeatFrequency: null,
      repeatInterval: null,
      repeats: [],
      showLocation: null,
      showName: null,
      showUrl: null,
      startTimeWall: null,
      tags: [],
      horseIds: [],
      memberIds: [],
      title: '',
      treatments: [],
      vaccine: null,
      vendor: null,
      createRecord: false
    };

    return new Event(payload);
  }

  function eventFromPayload(event, timeZone, vendors, transformDates) {
    const result = new Event(event);

    result.repeat = !!result.repeat;

    // temporary until details view is upgraded to work with objects
    if (transformDates) {
      result.date = convert(LocalDate.parse(event.dateDate)).toDate();

      if (!result.allDay && result.eventType !== 'SHOW') {
        result.startTimeWall = convert(LocalDateTime.of(LocalDate.now(),
          LocalTime.parse(event.startTimeWall))).toDate();
        result.endTimeWall = convert(LocalDateTime.of(LocalDate.now(),
          LocalTime.parse(event.endTimeWall))).toDate();
      }

      if (event.endsDate) {
        result.ends = convert(LocalDate.parse(event.endsDate)).toDate();
      }

      if (event.endOnDate) {
        result.endOn = convert(LocalDate.parse(event.endOnDate)).toDate();
      }
    }

    if (event.vendor) {
      const vendorData = find(vendors, function(obj) {
        return parseInt(obj.id) === parseInt(event.vendor); // set default value
      });
      if (vendorData) {
        result.vendor = new Vendor(vendorData);
      }
    }

    result.eventType = convertAppointmentToEvent(event.eventType);

    result.type(EventTypes[result.eventType]);

    if (result.assignments && result.assignments.length > 0) {
      result.assignments = result.assignments.map(assignment => {
        return {
          contact: {
            id: assignment.contactId,
            name: assignment.contactName
          },
          horse: {
            id: assignment.horseId,
            name: assignment.horseName
          },
          attended: assignment.attended
        };
      });
    }

    return result;
  }

  function eventToPayload(event, timeZone, selectedWeekdays, repeatedEventHasEndDate, assignments, reminders) {
    const result = angular.copy(event);

    result.eventType = result.types.value;
    result.allDay = result.allDay ? 1 : 0;
    result.repeat = result.repeat ? 1 : 0;

    if (!result.endOn || !repeatedEventHasEndDate) {
      delete result.endOnDate;
    } else {
      result.endOnDate = LocalDate.from(nativeJs(result.endOn)).format(DateTimeFormatter.ISO_LOCAL_DATE);
    }
    delete result.endOn;

    if (result.ends && result.eventType === 'SHOW') {
      result.endsDate = LocalDate.from(nativeJs(result.ends)).format(DateTimeFormatter.ISO_LOCAL_DATE);
    } else {
      delete result.endsDate;
    }
    delete result.ends;

    if (result.allDay || result.eventType === 'SHOW') {
      delete result.startTimeWall;
      delete result.endTimeWall;
    } else {
      result.startTimeWall = LocalTime.from(nativeJs(result.startTimeWall)).format(DateTimeFormatter.ofPattern('HH:mm'));
      result.endTimeWall = LocalTime.from(nativeJs(result.endTimeWall)).format(DateTimeFormatter.ofPattern('HH:mm'));
    }
    delete result.startTime;
    delete result.endTime;

    result.dateDate = LocalDate.from(nativeJs(result.date)).format(DateTimeFormatter.ISO_LOCAL_DATE);
    delete result.date;

    if (result.vendor) {
      result.vendor = result.vendor.id;
    }

    result.eventType = convertEventToAppointment(result.types.value);

    if (result.repeat) {
      result.repeats = buildRepeats(result, selectedWeekdays);
    }

    if (event.canHaveAssignments()) {
      if (assignments && assignments.length > 0) {
        result.assignments = assignments.map(assignment => {
          return {
            contactId: assignment.contact.id,
            horseId: assignment.horse.id
          };
        });
      }
    } else {
      result.assignments = [];
    }
    result.reminders = reminders;

    return result;
  }

  function convertAppointmentToEvent(eventType) {
    const eventTypes = {
      DENTIST_APPOINTMENT: DENTIST.value,
      FARRIER_APPOINTMENT: FARRIER.value,
      VET_APPOINTMENT: VET.value
    };

    return eventTypes[eventType] ? eventTypes[eventType] : eventType;
  }

  function convertEventToAppointment(eventType) {
    switch (eventType) {
      case DENTIST.value:
        return DENTIST_APPOINTMENT;

      case FARRIER.value:
        return FARRIER_APPOINTMENT;

      case VET.value:
        return VET_APPOINTMENT;

      default:
        return eventType;
    }
  }

  function buildRepeats(event, selectedWeekdays) {
    const results = [];
    if (event.repeatFrequency === 'WEEKLY') {
      angular.forEach(selectedWeekdays, function(day) {
        if (day !== '*') {
          results.push({
            repeatMonth: '*',
            repeatDay: '*',
            repeatWeekDay: day
          });
        }
      });
    } else {
      let obj = {};
      switch (event.repeatFrequency) {
        case 'YEARLY':
          obj = {
            repeatMonth: moment(event.date).format('MMMM Do'),
            repeatDay: '*',
            repeatWeekDay: '*'
          };
          break;
        case 'MONTHLY':
          obj = {
            repeatMonth: '*',
            repeatDay: moment(event.date).format('Do'),
            repeatWeekDay: '*'
          };
          break;
        case 'DAILY':
          obj = {
            repeatMonth: '*',
            repeatDay: '*',
            repeatWeekDay: '*'
          };
      }
      results.push(obj);
    }

    return results;
  }
}
