import {barnManagerModule} from '../../index.module';

barnManagerModule.controller('ActivityController', ActivityController);

ActivityController.$inject = [
  '$rootScope',
  '$scope',
  '$stateParams',
  'backLinkHistory',
  'activityRepository',
  'barnStorage',
  'responseHandler',
  'authenticationService',
  'hasPermission',
  'titleService',
  '$location',
  '$window',
  'listRepository'
];

function ActivityController(
  $rootScope,
  $scope,
  $stateParams,
  backLinkHistory,
  activityRepository,
  barnStorage,
  responseHandler,
  authenticationService,
  hasPermission,
  titleService,
  $location,
  $window,
  listRepository
) {
  let vm = this,
    barn,
    pageSize = 20,
    totalPages = 1;

  backLinkHistory.pushLink('Activity');

  vm.items = [];
  vm.hasWhiteBoardPermission = hasPermission('whiteboard:read');
  vm.queryActivities = queryActivities;

  vm.loadMore = loadMore;
  vm.hasMore = hasMore;
  vm.toggleCheckbox = toggleCheckbox;
  vm.isShowListingSection = false;
  vm.state = 'Loading';

  if (authenticationService.isLoggedIn()) {
    init();
  }

  function loadMore() {
    if (hasMore()) {
      pageSize += pageSize;
      queryActivities();
    }
  }

  function hasMore() {
    return totalPages > 1;
  }

  function getList() {
    vm.state = 'Loading';
    listRepository.search({
      tenantEnvironmentId: barn.id,
      showOnlyAssignedUser: true,
      pagesize: 9999
    })
      .then(function (response) {
        vm.state = 'Loaded';
        response.records = response.records.filter(list =>
          list.barnListCategory === 'CHECK_LIST' || list.barnListCategory === 'CALENDAR_TASK_LIST'
        );
        if (response.records && response.records.length > 0) {
          vm.list.items = response.records.map(record => ({
            title: record.title,
            items: record.items.map(item => ({
              ...item,
              listId: record.id,
              badgeClass: getBadgeClass(item.dueOn)
            }))
          }));
          let totalItems = 0;
          vm.list.items.forEach(record => {
            totalItems += record.items.length;
          });
          vm.totalItems = totalItems;
          vm.isShowListingSection = true;
        } else {
          vm.list.items = [];
          vm.isShowListingSection = false;
        }
      })
      .catch(function (error) {
        console.error('Error fetching activities:', error);
        vm.isShowListingSection = false;
      });
  }

  function queryActivities(isPinned?) {
    if (typeof barn !== 'undefined') {
      vm.list = vm.list || {items: [], id: null};
      getList()
      activityRepository.search(pageSize, isPinned).then(function (activities) {
        vm.items = activities.records.map(function (item) {
          switch (item.type) {
            case 'LIST_SIMPLE': {
              item.message = item.message.replace('details for ', '');
              break;
            }
            case 'HORSES_IMPORT': {
              item.message = `${item.message}: ${item.horses}`;
              item.horses = null;
              break;
            }
          }
          return item;
        });
        totalPages = activities.totalPages;
      }).catch(responseHandler.processError);
    }
  }

  function getBadgeClass(timestamp) {
    const today = new Date().setHours(0, 0, 0, 0);  // Start of today
    const itemDate = new Date(timestamp).setHours(0, 0, 0, 0);
    if (itemDate < today) {
      return 'past-date';
    } else if (itemDate === today) {
      return 'current-date';
    } else {
      return 'future-date';
    }
  }


  function init() {
    barn = barnStorage.getEnv();
    titleService.setTitle('Activity');
    $rootScope.pageConf.class = 'page--barn';
    $rootScope.pageConf.htmlTagClass = 'overview-page';
    initCurrentSection();
    $scope.$on('$locationChangeSuccess', (event, newUrl, oldUrl) => {
      if (newUrl === oldUrl) {
        return;
      }
      initCurrentSection();
      $window.scrollTo(0, 0);
    });
  }

  function initCurrentSection() {
    const sectionParam = $stateParams.section;
    if (sectionParam) {
      if (sectionParam === 'whiteboard' && !vm.hasWhiteBoardPermission) {
        vm.currentSection = 'timeline';
        $location.search('section', vm.currentSection);
        return;
      }
      if (sectionParam === 'listSection') {
        vm.currentSection = 'listSection';
      } else {
        vm.currentSection = sectionParam;
      }
    } else {
      vm.currentSection = vm.hasWhiteBoardPermission ? 'whiteboard' : 'timeline';
    }
  }

  function toggleCheckbox(item) {
    listRepository.updateCheckItemState(item.listId, item)
      .then(function () {
        getList();
      })
      .catch(responseHandler.processError);
  }

  $scope.$watch('vm.list.items', function (newVal, oldVal) {
    if (newVal) {
      let totalItems = 0;
      newVal.forEach(record => {
        totalItems += record.items.length;
      });
      vm.totalItems = totalItems;
    }
  }, true);
}
