import {AllHorseCategories} from '../horse-category';
import {HorseOwner} from './horse-owner';
import {Horse} from './horse';

export class HorseDetails implements Partial<Horse> {
  archived: number;
  breed: string;
  categories: AllHorseCategories;
  coggingsCertificateExpiration: string;
  color: string;
  created: string;
  dam: null;
  displayName: string;
  feedingNotes: string;
  feiPassportExpirationDate: string
  feiPassportNumber: string;
  foalDay: number;
  foalMonth: number;
  foalYear: string;
  height: { hands: string, inches: string };
  horseImageId: number;
  horseImageThumbnailId: number;
  horseOwner: HorseOwner;
  id: number;
  insuranceCompany: string;
  insuranceDate: string;
  insurancePhoneNumber: string;
  insurancePlan: string;
  insurancePolicy: string;
  leases: any;
  location: any;
  markings: string;
  microchipCode: string;
  name: string;
  notes: string;
  pinned: number;
  registrations: any[];
  sire: string;
  stallLocation: string;
  type: string;
  usefHorseCard: string;
  usefHorseResults: string;
  usefMembership: string;
  usefNumber: string;
  weight: string;
  weightUnit: string;
  foalDate: string;
  heightAsString: string;
  weightAsString: string;

  constructor(horse: Partial<Horse>) {
    Object.assign(this, { ...horse });
    this.foalDate = this.getFoalDate();
    this.heightAsString = this.getHeightAsString();
    this.weightAsString = this.getWeightAsString();
  }

  private getFoalDate(): string {
    if (this.foalYear) {
      if (this.foalMonth && this.foalDay) {
        return `${this.foalMonth}/${this.foalDay}/${this.foalYear}`;
      }
      return this.foalYear;
    }
    return null;
  }

  private getHeightAsString(): string {
    if (this.height === null) {
      return '';
    }
    return (this.height.hands ? this.height.hands + ' hands' : '') + (this.height.hands && this.height.inches ? ', ' : '') + (this.height.inches ? this.height.inches + ' inches' : '');
  }

  private getWeightAsString(): string {
    return [this.weight, this.weightUnit].join(' ');
  }
}
