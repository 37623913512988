import {barnManagerModule} from '../index.module';
import {environment} from "@environment";
import {downgradeInjectable} from "@angular/upgrade/static";
import {AuthorizedStorage} from "../../app/core/authorized";

barnManagerModule.config(publicRoutes);

barnManagerModule.factory('authorizedStorage', downgradeInjectable(AuthorizedStorage));

publicRoutes.$inject = ['$stateProvider', '$urlMatcherFactoryProvider', 'StripeCheckoutProvider'];
function publicRoutes($stateProvider, $urlMatcherFactoryProvider, StripeCheckoutProvider) {
  $urlMatcherFactoryProvider.strictMode(false);

  $stateProvider
    .state('signup', {
      url: '/n/signup',
      data: { isPublic: true },
      views: {
        content: {
          templateUrl: 'signup.html',
          controller: 'SignupController',
          controllerAs: 'vm',
          resolve: {
            stripe: StripeCheckoutProvider.load
          }
        }
      }
    });

  $stateProvider.state('sink', {
    url: '/*path',
    template: ''
  });
}

barnManagerModule.run(['$rootScope', '$state', 'authorizedStorage', function($rootScope, $state , authorizedStorage) {
  $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
    if (toState.name === 'signup' && !environment.enableSignUp  && !authorizedStorage?.sysAdmin) {
      event.preventDefault();
      $state.go('no-permissions');
    }
  });
}]);
