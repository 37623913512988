import {barnManagerModule} from '../index.module';

barnManagerModule.factory('titleService', titleService);

titleService.$inject = ['ng2TitleService'];
export function titleService(ng2TitleService) {
  return {
    setTitle: setTitle
  };

  function setTitle(title: string, horseName?: string): void {
    ng2TitleService.setTitle(title + (horseName ? ' :: ' + horseName : '') + ' :: Barn Manager');
  }
}
