import {BmEnvironmentType} from './bm-environment-type';

export const environment: BmEnvironmentType = {
  environmentName: 'stage',
  production: true,
  apiUrl: '/',
  intercomSettings: {
    appId: '5qur5uee',
    enabled: true
  },
  authorizedLink: '/n/activity',
  stripeCredentials: {
    key: 'pk_test_ITPKaO1z1zTbuI7Eitawd0qu'
  },
  notificationsRequestIntervalEnabled: true,
  enableRemoteLogging: true,
  logRocketKey: 'dnd3qt/barnmanager-stage',
  enableSignUp: true
};
