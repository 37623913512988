import {barnManagerModule} from '../../index.module';

barnManagerModule.controller('HeaderHorseController', HeaderHorseController);

HeaderHorseController.$inject = ['$rootScope', 'horseCache', 'hasPermission','barnStorage','rAttachments','unreadService','$window'];
function HeaderHorseController($rootScope, horseCache, hasPermission,  barnStorage,rAttachments,unreadService,$window,) {
  const vm = this, url = $window.URL || $window.webkitURL;
  vm.hasEventsReadPermission = hasPermission('events:read') || hasPermission('lessons:full');
  vm.hasConversationsReadPermission = hasPermission('conversations:read');
  vm.hasRecordsReadPermission = hasPermission('records:read');
  vm.hasListsReadPermission = hasPermission('lists:read');
  vm.hasFeedReadPermission = hasPermission('feed:read');
  vm.horse = null;

  init();

  function loadHorse() {
    const horse = horseCache.horse();
    if (horse) {
      $rootScope.pageConf.horseName = horse.name;
      vm.horse = horse;
    }
  }

  function getLogo(attachmentId) {
    rAttachments.get({ id: attachmentId }).$promise.then(function(attachment) {
      vm.logoUrl = url.createObjectURL(new Blob([attachment.data]));
      vm.done = true;
    });
  }

  function init() {
    loadHorse();
    vm.barn = barnStorage.getEnv();
    unreadService.query(vm.barn.id, function(response) {
      vm.unreads = response;
    });
    rAttachments.query({ modelType: 'Barn', modelId: vm.barn.id }).$promise.then(function(attachments) {
      if (attachments.length) {
        vm.hasLogo = true;
        getLogo(attachments[0].id);
      }
      else {
        vm.done = true;
      }
    }).catch(function() {
      vm.done = true;
    });
  }
}
