import {barnManagerModule} from '../../../index.module';

barnManagerModule.directive('eventTypeValid', eventTypeValid);

eventTypeValid.$inject = ['EventTypes'];

function eventTypeValid(EventTypes) {
  return {
    require: 'ngModel',
    link: function (scope, elm, attrs, ctrl) {
      ctrl.$validators.eventTypeValid = function (modelValue, _) {
        return !(modelValue == null || modelValue === EventTypes.NONE);
      };
    }
  };
}
