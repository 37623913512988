import {barnManagerModule} from '../index.module';

barnManagerModule.factory('eventsService', EventsService);

EventsService.$inject = ['$state', 'selectDialog', 'confirmDialog', 'rEvents', 'responseHandler', 'MessageFrames'];
function EventsService(
  $state,
  selectDialog,
  confirmDialog,
  rEvents,
  responseHandler,
  MessageFrames
) {

  function remove(event, instance) {
    if (event.repeat && instance) {
      return selectDialog.open('You are about to delete:', [
        { option: 'single', label: 'This event instance' },
        { option: 'thisAndFollowing', label: 'This and all of the following instances' },
        { option: 'all', label: 'All instances of the series' }
      ], 'Delete', 'Please note that by deleting this event, you are also deleting any list that is associated with the event.'
      ).then(function(option) {
        let promise;
        let message;
        switch (option) {
          case 'single': {
            message = 'Single Event Instance successfully deleted'
            promise = rEvents.deleteInstance({
              eventId: event.id,
              instance: instance
            }).$promise;
            break;
          }
          case 'thisAndFollowing': {
            message = 'Given and all of the following successfully deleted'
            promise = rEvents.deleteAllFollowingInstances({
              eventId: event.id,
              instance: instance
            }).$promise;
            break;
          }
          case 'all': {
            message = 'Event Series was successfully deleted'
            promise = rEvents.delete({
              userId: event.authorId,
              eventId: event.id
            }).$promise;
            break;
          }
        }
        return promise.then(function() {
          responseHandler.successMessage(message);
        }).catch(function(error) {
          responseHandler.processError(error, null, MessageFrames.TOP);
        });
      });
    } else {
      const text = 'Are you sure you want to permanently delete this event? Please note that by deleting this event, you are also deleting any list that is associated with the event.';
      let type = null;
      if (event.repeat) {
        type = 'event-note';
      }
      return confirmDialog.open(text, type, 'Delete').then(function() {
        return rEvents.delete({ userId: event.authorId, eventId: event.id }).$promise.then(function() {
          responseHandler.successMessage('Event successfully deleted');
        }).catch(function(error) {
          responseHandler.processError(error, null, MessageFrames.TOP);
        });
      }).catch(function() {
      });
    }
  }

  function navigateToEdit(event, curHorseId, instance) { // eslint-disable-line
    let routeName, routeParams;

    if (curHorseId > 0) {
      routeName = 'eventHorseEdit';
      routeParams = {
        id: curHorseId,
        eventId: event.id,
        author: event.authorId
      };
    } else {
      routeName = 'eventEdit';
      routeParams = {
        id: event.id,
        author: event.authorId
      };
    }

    if (event.repeat) {
      return selectDialog.open('Select edit mode:', [
        { option: 'single', label: 'This event instance' },
        { option: 'thisAndFollowing', label: 'This and all of the following instances' },
        { option: 'all', label: 'All instances of the series' }
      ], "Edit").then(function(option) {
        switch (option) {
          case 'single': {
            routeParams.instance = instance;
            break;
          }
          case 'thisAndFollowing': {
            routeParams.instance = instance;
            routeParams.thisAndFollowing = true;
            break;
          }
        }
        $state.go(routeName, routeParams);
      });
    } else {
      $state.go(routeName, routeParams);
    }
  }

  function togglePin(event) {
    const params: any = {
      eventId: event.id
    };

    if (event.repeat) {
      params.instance = event.dateDate;
      rEvents.toggleInstancePin(params);
    } else {
      rEvents.togglePin(params);
    }
  }

  return {
    remove: remove,
    navigateToEdit: navigateToEdit,
    togglePin: togglePin
  };
}
