import {barnManagerModule} from '../../index.module';
import {nativeJs, ZonedDateTime} from '@js-joda/core';
import {CheckListItemState} from '../../resources/listRepository';

barnManagerModule.directive('bmCheckList', checkList);

function checkList() {
  let directive = {
    restricted: 'E',
    templateUrl: 'check-list.html',
    controller: CheckList,
    controllerAs: 'checkList',
    bindToController: true,
    scope : {
      items : '=',
      editMode: '=',
      disableCheckboxes: '=',
      onToggle: '&',
      allUsers: '=',
      showItemChanges: '=',
      addMode: '=',
      eventDate: '=',
    }
  };

  CheckList.$inject = ['rUser', 'barnStorage', 'hasPermission', 'dateTimeUpgraded']

  function CheckList(rUser, barnStorage, hasPermission, dateTimeUpgraded) {
    let vm = this;

    vm.$onInit = function() {

      vm.hasListsReadPermission = hasPermission('lists:read');
      vm.checkListItemState = CheckListItemState;
      vm.fromUTCDateToLocalDateTimeFormatted = dateTimeUpgraded.fromUTCDateToLocalDateTimeFormatted;

      vm.calendarOpen = false;
      vm.updateMode = false;
      vm.updateIndex = -1;
      vm.users = [];
      vm.assignees = [];

      vm.addItem = addItem;
      vm.openCalendar = openCalendar;
      vm.getUser = getUser;
      vm.editItem = editItem;
      vm.deleteItem = deleteItem;
      vm.cancelUpdate = cancelUpdate;
      vm.updateItem = updateItem;
      vm.onChange = onChange;
      vm.onChangeAddItem = onChangeAddItem;
      vm.openCalendarAdd = openCalendarAdd;
      vm.isAddCalendarOpen = false;
      vm.addDueOn = vm.eventDate? vm.eventDate : null;
      vm.hideAndShowAddListItemForm = false;
      vm.items = vm.items || [];

      loadUsers();

      function openCalendar() {
        vm.calendarOpen = true;
      }

      function openCalendarAdd() {
        vm.isAddCalendarOpen = true;
      }

      function addItem(checkListForm?: any) {
        if (checkListForm.$valid) {
          vm.items.push({
            state: vm.checkListItemState.INCOMPLETE,
            name: vm.addItemName,
            dueOn: vm.addDueOn ? ZonedDateTime.from(nativeJs(vm.addDueOn)).toLocalDate().toJSON() : vm.eventDate ? vm.eventDate : null,
            assignees: vm.addAssignees
          });
          checkListForm.$setPristine();
          checkListForm.$setUntouched();
        }
        vm.editingItemIndex = null;
        clear();
      }

      function editItem(item, index) {
        vm.hideAndShowAddListItemForm = true;
        vm.editingItemIndex = index;
        vm.itemId = item.id ? item.id : null;
        vm.itemName = item.name;
        vm.dueOn = item.dueOn ? new Date(item.dueOn) : null;
        // if (!item.assignedToId) {
        //   item.assignedToId = null;
        // }
        // vm.assignee = find(vm.users, user => user.value === item.assignedToId);
        if (item.assignees?.length) {
          vm.assignees = item.assignees;
          vm.selected = item.assignees.map(assignee => ({name: assignee.assignedToName, value: assignee.assignee}));
        }

        vm.updateMode = true;
        vm.updateIndex = index;
      }

      function updateItem() {
        vm.items.splice(vm.updateIndex, 1, {
          id: vm.itemId,
          state: vm.items[vm.updateIndex].state,
          name: vm.itemName,
          // assignedToId: vm.assignee.value,
          dueOn: vm.dueOn ? ZonedDateTime.from(nativeJs(vm.dueOn)).toLocalDate().toJSON() : null,
          assignees: vm.assignees
        });
        vm.updateIndex = -1;
        vm.editingItemIndex = null;
        clear();
      }

      function cancelUpdate() {
        clear();
        vm.updateIndex = -1;
        vm.updateMode = false;
        vm.editingItemIndex = null;
        vm.hideAndShowAddListItemForm = false;
      }

      function deleteItem(index) {
        if (index === vm.updateIndex) {
          clear();
          vm.updateMode = false;
          vm.updateIndex = -1;
        }

        vm.updateIndex = vm.updateIndex < index ? vm.updateIndex : vm.updateIndex - 1;
        vm.items.splice(index, 1);
      }

      function clear() {
        vm.itemName = '';
        vm.dueOn = false;
        vm.calendarOpen = false;
        vm.assignee = vm.users[0];
        vm.assignees = [];
        vm.selected = [];
        vm.itemId = null;
        vm.updateMode = false;
        vm.updateIndex = -1;
        vm.addItemName = null;
        vm.addDueOn = vm.eventDate? vm.eventDate : null;
        vm.addAssignees =[];
        vm.selectedAddItem = [];
        vm.isAddCalendarOpen = false;
        vm.hideAndShowAddListItemForm = false;
      }

      function getUser(id) {
        return vm.users.filter(obj => obj.value == id)[0];
      }

      function loadUsers() {
        if (!vm.allUsers) {
          rUser.search({
            tenantEnvironmentId: barnStorage.getEnvId(),
            archived: false,
            pagesize: 999
          }).$promise.then(function(response) {
            initUsers(response.records);
          });
        } else {
          initUsers(vm.allUsers);
        }
      }

      function initUsers(users) {
        // vm.users = [
        //   {name: 'Unassigned', value: null},
        //   ...users.map(user => ({name: user.displayName, value: user.id}))
        // ];
        vm.users = users.map(user => ({name: user.displayName, value: user.id}));
      }

      function onChange(selected: any) {
        vm.assignees = selected.map(user => ({assignee: user.value, assignedToName: user.name}));
      }

      function onChangeAddItem(selected: any) {
        vm.addAssignees = selected.map(user => ({assignee: user.value, assignedToName: user.name}));
      }
    };

  }

  return directive;
}
