import {barnManagerModule} from '../../index.module';
import angular from 'angular';
import {find} from 'lodash';

barnManagerModule.directive('bmNotificationsArea', notificationsArea);

function notificationsArea() {
  let directive = {
    restricted: 'E',
    templateUrl: 'notifications-area.html',
    controller: NotificationsAreaController,
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      description: '<',
      idsMode: '<',
      model: '<', // { memberIds: [], allTeamMembersNotifications: false }
      onMembersChange: '&',
      assignedMembers: '=', // idsMode = false or undefined
      previewMode: '=',
      allUsers: '<'
    }
  };

  NotificationsAreaController.$inject = ['$scope', 'listsService']

  function NotificationsAreaController($scope, listsService) {
    let vm = this;
    let splittedFirst = false;

    vm.members = [];
    vm.assignedMemberIds = [];

    vm.membersChanged = membersChanged;

    function membersChanged(selected) {
      if (selected) {
        vm.assignedMemberIds = selected?.map(i => i.id || i);
      }
      if (vm.idsMode) {
        vm.onMembersChange({
          model: {
            memberIds: vm.assignedMemberIds,
            allTeamMembersNotifications: vm.allTeamMembersNotifications
          }
        });
      } else {
        listsService.setMembers(vm.assignedMemberIds);
        vm.model.allTeamMembersNotifications = vm.allTeamMembersNotifications;
        vm.assignedMembers = vm.assignedMemberIds.map(function(id) {
          return find(vm.members, { id: id });
        });
      }
    }

    vm.$onInit = function() {
      $scope.$watch('vm.model.allTeamMembersNotifications', function(newVal) {
        if (angular.isDefined(newVal)) {
          vm.allTeamMembersNotifications = newVal;
        }
      });
      $scope.$watch('vm.model.memberIds', function(newVal) {
        if (angular.isDefined(newVal)) {
          vm.assignedMemberIds = newVal;
        }
      });

      $scope.$watch('vm.assignedMembers', function(newVal) {
        if (angular.isDefined(newVal)) {
          if (!vm.previewMode) {
            vm.assignedMemberIds = listsService.getIds(newVal);
            listsService.setMembers(vm.assignedMemberIds);
          }
        }
      });

      $scope.$watch('vm.allUsers', function(newVal, prevVal) {
        if (newVal !== prevVal || !splittedFirst) {
          vm.members = newVal;
          splittedFirst = true;
        }
      });
    };
  }

  return directive;
}
