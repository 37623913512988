import {barnManagerModule} from '../../index.module';
import {merge} from 'angular';
import {find, set} from 'lodash';

enum ConversationSort {
  originalPostDate = 'originalPostDate',
  lastUpdated = 'lastUpdated',
  lastCommentedAt = 'lastCommentedAt',
  alphabeticallyAZ = 'alphabeticallyAZ'
}

barnManagerModule.controller('ConversationsController', ConversationsController);

ConversationsController.$inject = [
  '$log',
  '$scope',
  '$state',
  '$timeout',
  'backLinkHistory',
  'rConversations',
  'rUserConversations',
  'barnStorage',
  'PAGE_SIZE',
  '$location',
  'titleService',
  'hasPermission',
  'goNative',
  'userSettingsStorage',
  'userStorage',
  'rModelLists',
  'horseCache',
  '$rootScope'
];
function ConversationsController(
  $log,
  $scope,
  $state,
  $timeout,
  backLinkHistory,
  rConversations,
  rUserConversations,
  barnStorage,
  PAGE_SIZE,
  $location,
  titleService,
  hasPermission,
  goNative,
  userSettingsStorage,
  userStorage,
  rModelLists,
  horseCache,
  $rootScope
) {
  let vm = this, barn, timeout, pagesize = PAGE_SIZE;

  function replaceLastHistory() {
    backLinkHistory.popLink();
    pushHistory();
  }

  function pushHistory() {
    if ($state.params.id) {
      backLinkHistory.pushHorseLink('conversations', $state.params.id);
    } else {
      backLinkHistory.pushLink('Conversations');
    }
  }

  pushHistory();

  vm.hasAdminFullPermission = hasPermission('admin:full');
  vm.hasConversationsFullPermission = hasPermission('conversations:full');
  vm.hasConversationsReadPermission = hasPermission('conversations:read');
  vm.isGoNative = goNative.isGoNative();

  vm.routeUrl = routeUrl;
  vm.update = update;
  vm.loadMore = loadMore;
  vm.search = search;

  vm.conversations = [];
  vm.totalPages = 1;

  vm.status = {
    empty: false,
    error: false,
    loaded: false,
    busy: false,
    query: false,
    message: '',
    errorMessage: ''
  };

  vm.properties = ['itemTitle', 'authorName', 'pFirstLast'];

  vm.sortSettingProperty = 'conversationLastSelectedSortOption';
  vm.sortOptions = [
    {name: 'Original Post Date', value: ConversationSort.originalPostDate},
    {name: 'Last Updated', value: ConversationSort.lastUpdated},
    {name: 'By Latest Comment', value: ConversationSort.lastCommentedAt},
    {name: 'Alphabetically A-Z', value: ConversationSort.alphabeticallyAZ},
  ];
  vm.sortBy = find(vm.sortOptions, option => option.value === userSettingsStorage.getUserSettings()[vm.sortSettingProperty]) || vm.sortOptions[2];

  vm.availableFilters = [
    {name: 'All Conversations', value: {'archived': false } },
    {name: 'Archived', value: {'archived': true } },
    {name: 'Unread', value: {'unread': 0} }
  ];
  vm.filter = vm.availableFilters[0];
  vm.horseId = $state.params.id;

  init();
  function loadHorse() {
    const horse = horseCache.horse();
    if (horse) {
      $rootScope.pageConf.horseName = horse.name;
      vm.horse = horse;
    }
  }

  function loadMore() {
    if (vm.totalPages > 1) {
      pagesize += pagesize;
      search();
    }
  }

  function update(conversation) {
    conversation.pinned = conversation.pinned ? 0 : 1;
    rUserConversations.togglePin({userId: conversation.authorId, conversationId: conversation.id}).$promise.then(() => {
      vm.conversations = vm.conversations.sort((a, b) => a.title.localeCompare(b.title)).sort((a, b) => b.pinned - a.pinned);
    }).catch(() => conversation.pinned = conversation.pinned ? 0 : 1);
  }

  function routeUrl(stateParams) {
    if ($state.current.name === 'conversationsHorse') {
      return $state.href('conversationHorseDetails',
        merge(stateParams, {
          id: $state.params.id,
          conversationId: stateParams.id
        }
      ));
    }
    return $state.href('conversationDetails', stateParams);
  }

  function setUpFilters() {
    const ls = $location.search();
    if (ls['filter']) {
      vm.filter = find(vm.availableFilters, function(value) {
        return value.name === ls['filter'];
      });
    }
    if (ls['keyword']) {
      vm.keyword = ls['keyword'];
    }
    if (ls['sortBy']) {
      vm.sortBy = find(vm.sortOptions, option => option.value === ls['sortBy']);
    }
  }

  function init() {
    titleService.setTitle('Conversations');
    timer();
    setUpFilters();
    barn = barnStorage.getEnv();
    watchFilters();
    search();
    loadHorse();
  }

  function watchFilters() {
    let timeoutPromise, delay = 600;
    $scope.$watch('vm.filter', function(newFilter, oldFilter) {
      if (newFilter !== oldFilter) {
        pagesize = PAGE_SIZE;
        $location.search('filter', newFilter.name);
        replaceLastHistory();
        search();
      }
    }, true);
    $scope.$watch('vm.keyword', function(newKeyword, oldKeyword) {
      if (newKeyword !== oldKeyword) {
        $timeout.cancel(timeoutPromise);
        timeoutPromise = $timeout(function() {
          $location.search('keyword', newKeyword);
          replaceLastHistory();
          search();
        }, delay);
      }
    }, true);
    $scope.$watch('vm.sortBy', function(newVal, oldVal) {
      if (newVal.value !== oldVal.value) {
        $location.search('sortBy', newVal.value);
        replaceLastHistory();
        search();
        changeConversationLastSelectedSortOption(newVal.value)
      }
    }, true);
  }

  function search() {
    timer();
    vm.status.busy = true;
    const queryParams = {
      horseId: $state.params.id,
      pagesize: pagesize,
      sort: vm.sortBy.value,
      tenantEnvironmentId: barn.id,
      text: vm.keyword,
      allHorses: true
    };

    if ($state.current.name === 'conversations') {
      delete queryParams.allHorses;
    }

    if (vm.filter) {
      merge(queryParams, vm.filter.value);
    }

    rConversations.query(queryParams, function(response) {
      vm.status.busy = false;
      vm.status.loaded = true;
      vm.conversations = response.records;
      vm.totalPages = response.totalPages;
      vm.totalRecords = response.totalRecords;
    }, function(error) {
      $log.error(error);
      vm.status.busy = false;
      vm.status.error = true;
      vm.status.errorMessage = 'Can\'t load conversations. Error: ' + error.status + ' ' + error.statusText;
    });
  }

  function timer() {
    vm.status.message = '';
    $timeout.cancel(timeout);
    timeout = $timeout(function() {
      vm.status.message = 'Oops! It is taking more time than expected.';
    }, 4000);
  }

  function changeConversationLastSelectedSortOption(value: ConversationSort) {
    vm.user = userStorage.getUser();
    const preferences = {userId: vm.user.id};
    set(preferences, vm.sortSettingProperty, value);

    rModelLists.patchUserPreferences(preferences).$promise
      .then(() => {
        const userSettings = set(userSettingsStorage.getUserSettings(), vm.sortSettingProperty, value);
        userSettingsStorage.setUserSettings(userSettings);
      });
  }
}
