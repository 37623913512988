<router-outlet></router-outlet>
<div [hidden]="!ng1Active" class="app-wrap" ng-class="pageConf.htmlTagClass">
  <bm-confirm-dialog *ngIf="ng1Bootstrapped"></bm-confirm-dialog>

  <div class="app-wrap" ng-class="pageConf.class">
    <bm-test-api-endpoint-notification *ngIf="ng1Bootstrapped"></bm-test-api-endpoint-notification>
    <bm-header *ngIf="ng1Bootstrapped" [ng1BootstrappedAndActive]="ng1Active && ng1Bootstrapped"></bm-header>
    <bm-message-frame *ngIf="ng1Bootstrapped" [frame]="'top'"></bm-message-frame>
    <bm-notifications-sidebar *ngIf="ng1Bootstrapped"></bm-notifications-sidebar>

    <main id="app_main" role="main" aria-label="content" class="app-main" tabindex="-1">
      <div id="loading-icon" style="width: 200px"><img src="/assets/images/animated-spinner.svg" alt="ng1-loader">
        <br>Loading
        <br><br><bm-clear-cache *ngIf="ng1Bootstrapped"></bm-clear-cache>
      </div>
      <div id="main-content" style="display: none">
        <bm-search-bar *ngIf="ng1Bootstrapped" [state]="'search'" [queryVarName]="'term'"></bm-search-bar>
        <div class="main-body main-body-container">
          <div class="col-lg col-md-12 side-nav__container">
            <div ui-view="header"></div>
          </div>
          <div class="content-area-main-div col-lg col-md-12">
            <div ui-view="header"></div>
            <div ui-view="messages"></div>
            <div ui-view="content"></div>
          </div>
        </div>
      </div>
      <bm-footer *ngIf="ng1Bootstrapped"></bm-footer>
    </main>
  </div>
</div>
