import {barnManagerModule} from '../../index.module';
import {find, flatten, map, merge, values} from 'lodash';

barnManagerModule.controller('RecordsController', RecordsController);

RecordsController.$inject = [
  '$state',
  '$timeout',
  '$scope',
  '$location',
  'backLinkHistory',
  'responseHandler',
  'PAGE_SIZE',
  'RecordTypes',
  'recordRepository',
  'titleService',
  'hasPermission',
  'horseCache',
  '$rootScope'
];
function RecordsController(
  $state,
  $timeout,
  $scope,
  $location,
  backLinkHistory,
  responseHandler,
  PAGE_SIZE,
  RecordTypes,
  recordRepository,
  titleService,
  hasPermission,
  horseCache,
  $rootScope
) {
  let vm = this, timeout, pagesize = PAGE_SIZE;

  vm.hasRecordsFullPermission = hasPermission('records:full');
  vm.horseId = $state.params.id;

  function replaceLastHistory() {
    backLinkHistory.popLink();
    pushHistory();
  }

  function pushHistory() {
    if ($state.params.id) {
      backLinkHistory.pushHorseLink('records', $state.params.id);
    } else {
      backLinkHistory.pushLink('Records');
    }
  }

  pushHistory();

  vm.routeUrl = routeUrl;
  vm.update = update;
  vm.loadMore = loadMore;
  vm.search = search;

  vm.records = [];
  vm.totalPages = 1;

  vm.sortByLastUpdated = true;

  vm.status = {
    empty: false,
    error: false,
    loaded: false,
    busy: false,
    query: false,
    message: '',
    errorMessage: ''
  };

  vm.properties = ['itemTitle', 'authorName', 'pFirstLast'];

  vm.orderBy = [
    {name: 'Newest',  value: {'reverse' : true} },
    {name: 'Oldest',  value: {'reverse' : false} }
  ];

  vm.order = vm.orderBy[0];

  vm.availableFilters = flatten(<any>[
    {name: 'All Records', section: 'Activity', value: {'archived': false } },
    {name: 'Archived', section: 'Activity', value: {'archived': true } },
    {name: 'Unread', section: 'Activity', value: {'unread': 0} },
    map(values(RecordTypes), function(recordType) {
      return {
        name: recordType.name,
        section: 'Record type',
        value: {
          type: recordType.value
        }
      };
    })
  ]);
  vm.filter = vm.availableFilters[0];

  init();

  function loadHorse() {
    const horse = horseCache.horse();
    if (horse) {
      $rootScope.pageConf.horseName = horse.name;
      vm.horse = horse;
    }
  }


  function update(record) {
    record.pinned = record.pinned ? 0 : 1;
    recordRepository.togglePin(record.id).then(() => {
      vm.records = vm.records.sort((a, b) => a.title.localeCompare(b.title)).sort((a, b) => b.pinned - a.pinned);
    }).catch(() => record.pinned ? 0 : 1);
  }

  function routeUrl(stateParams) {
    if ($state.current.name === 'recordsHorse') {
      return $state.href('recordHorseDetails',
        merge(stateParams, {
          id: $state.params.id,
          recordId: stateParams.id
        })
      );
    }

    return $state.href('recordDetails', stateParams);
  }

  function setUpFilters() {
    const ls = $location.search();
    if (ls['filter']) {
      vm.filter = find(vm.availableFilters, function(value) {
        return value.name === ls['filter'];
      });
    }
    if (ls['keyword']) {
      vm.keyword = ls['keyword'];
    }
    vm.sortByLastUpdated = (ls['sortByLastUpdated'] !== 'false');
  }

  function init() {
    titleService.setTitle('Records');
    timer();
    setUpFilters();

    watchFilters();
    search();
    loadHorse()
  }

  function watchFilters() {
    let timeoutPromise, delay = 600;
    $scope.$watch('vm.keyword', function(newKeyword, oldKeyword) {
      if (newKeyword !== oldKeyword) {
        $timeout.cancel(timeoutPromise);
        timeoutPromise = $timeout(function() {
          $location.search('keyword', newKeyword);
          replaceLastHistory();
          search();
        }, delay);
      }
    }, true);

    $scope.$watch('vm.filter', function(newFilter, oldFilter) {
      if (newFilter !== oldFilter) {
        pagesize = PAGE_SIZE;
        $location.search('filter', newFilter.name);
        replaceLastHistory();
        search();
      }
    }, true);

    $scope.$watch('vm.sortByLastUpdated', function(newVal, oldVal) {
      if (newVal !== oldVal) {
        $location.search('sortByLastUpdated', newVal);
        replaceLastHistory();
        search();
      }
    }, true);
  }

  function loadMore() {
    if (vm.totalPages > 1) {
      pagesize += pagesize;
      search();
    }
  }

  function search() {
    timer();
    vm.status.busy = true;
    const queryParams: any = {
      horseId: $state.params.id,
      pagesize: pagesize,
      sort: vm.sortByLastUpdated ? 'updated' : 'date',
      text: vm.keyword
    };

    if (vm.filter) {
      merge(queryParams, vm.filter.value);
    }

    if ($state.current.name !== 'records') {
      queryParams.allHorses = true;
    }

    recordRepository.search(queryParams).then(function(response) {
      vm.status.busy = false;
      vm.status.loaded = true;
      vm.records = response.records;
      vm.totalPages = response.totalPages;
      vm.totalRecords = response.totalRecords;
    }).catch(function(error) {
      vm.status.error = true;
      responseHandler.processError(error);
    });
  }

  function timer() {
    vm.status.message = '';
    $timeout.cancel(timeout);
    timeout = $timeout(function() {
      vm.status.message = 'Oops! It is taking more time than expected.';
    }, 4000);
  }
}
