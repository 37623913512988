import {UserRole} from './user-role';

export abstract class User {
  id: number;
  allHorsesAccess: boolean;
  archived: boolean;
  created: string;
  displayName: string;
  email: string;
  firstName: string;
  intercomId: string;
  updated?: string;
  userRoles: UserRole[];
  userTenants: UserTenant[];
  username: string;
  permissions: string[];
  isInvoicingEnabled: boolean;
  uuid: string;
  state?: string;
  sysAdmin?: boolean;
}

export interface UserTenant {
  tenant: Tenant;
  tenantAdmin: boolean;
  userId: number;
}

export interface Tenant {
  accessPolicy: TenantAccessPolicy;
  created: string;
  id: number;
  invoicingInvitedAt: string;
  name: string;
  tenantEnvironmentId: number;
  tenantEnvironments: Array<TenantEnvironment>
  timeZone: string;
  updated: string;
}

export interface TenantEnvironment {
  created: string;
  description: string;
  id: number;
  name: string;
  updated: string;
}

export enum TenantAccessPolicy {
  None = 'None',
  Canceled = 'Canceled',
  Starter = 'Starter',
  Essential = 'Essential',
  Pro = 'Pro',
  Legacy = 'Legacy'
}
