import {barnManagerModule} from '../../index.module';

barnManagerModule.service('selectDialog', SelectDialog);

SelectDialog.$inject = ['$q'];
function SelectDialog($q) {
  const vm = this;
  let listener = null;
  let deferred = null;

  vm.closed = function() {
    deferred.reject('closed');
  };

  vm.chosen = function(option) {
    deferred.resolve(option);
  };

  vm.open = function(title, options, affirmative, note?) {
    deferred = $q.defer();

    if (!listener) {
      throw 'No listener is registered for selectDialog';
    }
    listener.open(title, options, affirmative, note ? note : '');

    return deferred.promise;
  };

  vm.subscribe = function(aListener) {
    listener = aListener;
  };
}
