import {barnManagerModule} from '../../index.module';

barnManagerModule
  .component('bmSelectDialog', {
    templateUrl: 'select-dialog.html',
    controller: SelectDialogController,
    controllerAs: 'vm'
  });

SelectDialogController.$inject = ['selectDialog'];
function SelectDialogController(selectDialog) {
  const vm = this;

  vm.close = function() {
    vm.visible = false;
    vm.title = '';
    vm.affirmative = 'Ok';
    vm.color = ' ';
    vm.options = [];
    vm.note = '';
    selectDialog.closed();
  };

  vm.chosen = function() {
    vm.visible = false;
    selectDialog.chosen(vm.chosenOption);
  };

  vm.open = function(title, options, affirmative, note) {
    vm.title = title;
    vm.affirmative = affirmative;
    if (affirmative === 'Delete') {
      vm.color = '#C41E3A';
    }
    vm.options = options;
    vm.note = note;
    vm.visible = true;
    vm.chosenOption = vm.options[0].option;
  };

  vm.$onInit = function() {
    selectDialog.subscribe(vm);
  };
}
