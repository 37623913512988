import {barnManagerModule} from '../index.module';
import {merge} from 'lodash';


export enum CheckListItemState {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
}

barnManagerModule.factory('listRepository', ['$http', 'barnStorage', ($http, barnStorage) => {

  return {
    search: search,
    pin: pin,
    unpin: unpin,
    duplicate: duplicate,
    archive: archive,
    restore: restore,
    get: get,
    add: add,
    update: update,
    remove: remove,
    updateCheckItemState: updateCheckItemState,
    markAsRead: markAsRead,
    exportToCsv: exportToCsv,
    getEventWithExistingCalendarTaskList: getEventWithExistingCalendarTaskList,
  };

  function listsUrl() {
    return '/api/lists/v2';
  }

  function entityUrl(id) {
    return listsUrl() + '/' + id;
  }

  function search(params, passLinkedToEvent?: boolean) {
    let config = {
      pagesize: params.pagesize,
      direction: params.direction,
      sort: params.sort,
      horseId: params.horseId,
      allHorses: params.allHorses,
      pinned: params.pinned,
      text: params.text,
      archived: params.archived,
      barnListCategory: params.barnListCategory,
      showOnlyAssignedUser: params.showOnlyAssignedUser,
      linkedToEvent: params.linkedToEvent,
    };
    if (passLinkedToEvent) {
      config.linkedToEvent = false;
    } else {
      delete config.linkedToEvent;
    }
    return $http.get(listsUrl(), {
      params: mergeParams(config)
    }).then(transformResult);
  }

  function pin(id) {
    return $http.put(entityUrl(id) + '/pin').then(transformResult);
  }

  function unpin(id) {
    return $http.put(entityUrl(id) + '/unpin').then(transformResult);
  }

  function duplicate(id) {
    return $http.post(`${entityUrl(id)}/duplicate`).then(transformResult);
  }

  function archive(id) {
    return $http.put(`${entityUrl(id)}/archive`).then(transformResult);
  }

  function restore(id) {
    return $http.put(`${entityUrl(id)}/restore`).then(transformResult);
  }

  function get(id) {
    return $http.get(entityUrl(id)).then(transformResult);
  }

  function add(body) {
    return $http.post(listsUrl(), body).then(transformResult);
  }

  function update(id, body) {
    return $http.put(entityUrl(id), body).then(transformResult);
  }

  function remove(id) {
    return $http.delete(entityUrl(id)).then(transformResult);
  }

  function updateCheckItemState(id, item) {
    item.updating = true;
    const body = {
      state: item.state
    }
    return $http.put(entityUrl(id) + '/item/' + item.id, body)
      .then(transformResult)
      .then(response => Object.assign(item, response))
      .catch(() => {
        item.state = item.state === CheckListItemState.COMPLETE ? CheckListItemState.INCOMPLETE : CheckListItemState.COMPLETE;
      })
      .finally(() => item.updating = false);
  }

  function markAsRead(id) {
    return $http.put(entityUrl(id) + '/read').then(transformResult);
  }

  function exportToCsv(id) {
    return $http.post(entityUrl(id) + '/exportToCsv', {}, { transformResponse: [
      function(data) {
        return data;
      }
    ] }).then(transformResult);
  }

  function transformResult(result) {
    return result.data;
  }

  function mergeParams(params) {
    const defaultParams = {
      tenantEnvironmentId: barnStorage.getEnvId(),
      direction: 'DESC'
    };
    return merge(defaultParams, params || {});
  }

  function getEventWithExistingCalendarTaskList(eventId, eventDate, accessToken) {
    let url = `api/lists/v2/events/${eventId}/${eventDate}`;
    const headers = {accessToken: accessToken ?? ''};
    return $http.get(url, {headers: headers})
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}]);
