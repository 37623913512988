import {barnManagerModule} from '../../index.module';
import {find, flatten} from 'lodash';
import {merge} from 'angular';

barnManagerModule.controller('ListsController', ListsController);

ListsController.$inject = [
  'backLinkHistory',
  '$rootScope',
  '$state',
  '$timeout',
  '$scope',
  'responseHandler',
  'listRepository',
  'PAGE_SIZE',
  'titleService',
  'hasPermission',
  '$location',
  'horseCache'
]
function ListsController(
  backLinkHistory,
  $rootScope,
  $state,
  $timeout,
  $scope,
  responseHandler,
  listRepository,
  PAGE_SIZE,
  titleService,
  hasPermission,
  $location,
  horseCache
) {
  let vm = this, timeout, pagesize = PAGE_SIZE;

  vm.hasListsFullPermission = hasPermission('lists:full');
  vm.horseId = $state.params.id;

  function replaceLastHistory() {
    backLinkHistory.popLink();
    pushHistory();
  }

  function pushHistory() {
    if ($state.params.id) {
      backLinkHistory.pushHorseLink('lists', vm.horseId);
    } else {
      backLinkHistory.pushLink('Lists');
    }
  }
  pushHistory();

  $rootScope.pageConf.class = 'page--barn';

  vm.routeUrl = routeUrl;
  vm.update = update;
  vm.loadMore = loadMore;
  vm.search = search;

  vm.lists = [];
  vm.totalPages = 1;

  vm.sortByLastUpdated = true;

  vm.status = {
    empty: false,
    error: false,
    loaded: false,
    busy: false,
    query: false,
    message: '',
    errorMessage: ''
  };

  vm.properties = ['itemTitle', 'authorName', 'pFirstLast'];

  vm.orderBy = [
    {name: 'Newest',  value: {'reverse' : true} },
    {name: 'Oldest',  value: {'reverse' : false} }
  ];

  vm.order = vm.orderBy[0];

  vm.availableFilters = flatten(<any>[
    {name: 'All Lists', value: {'archived': false } },
    {name: 'Archived', value: {'archived': true } }
  ]);
  vm.filter = vm.availableFilters[0];

  vm.availableCategoryFilters = flatten(<any>[
    {name: 'All', value: {'barnListCategory': null } },
    {name: 'Check List', value: {'barnListCategory': 'CHECK_LIST' } },
    {name: 'Table List', value: {'barnListCategory': 'TABLE_LIST' } },
    {name: 'Calendar Task List', value: {'barnListCategory': 'CALENDAR_TASK_LIST' } }

  ]);

  vm.categoryFilter = vm.availableCategoryFilters[0];

  init();

  function loadHorse() {
    const horse = horseCache.horse();
    if (horse) {
      $rootScope.pageConf.horseName = horse.name;
      vm.horse = horse;
    }
  }

  function update(list) {
    const method = list.pinned ? listRepository.unpin : listRepository.pin;
    method(list.id).then(() => {
      list.pinned = !list.pinned;
      vm.lists = vm.lists.sort((a, b) => a.title.localeCompare(b.title)).sort((a, b) => b.pinned - a.pinned);
    }).catch(() => list.pinned = !list.pinned);
  }

  function routeUrl(stateParams) {
    if ($state.current.name === 'listsHorse') {
      return $state.href('listHorseDetails',
        merge(stateParams, {
          id: $state.params.id,
          listId: stateParams.id
        })
      );
    }

    return $state.href('listDetails', stateParams);
  }

  function setUpFilters() {
    const ls = $location.search();
    if (ls['filter']) {
      vm.filter = find(vm.availableFilters, function(value) {
        return value.name === ls['filter'];
      });
    }
    if (ls['categoryFilter']) {
      vm.categoryFilter = find(vm.availableCategoryFilters, function(value) {
        return value.name === ls['categoryFilter'];
      });
    }
    if (ls['keyword']) {
      vm.keyword = ls['keyword'];
    }
    vm.sortByLastUpdated = (ls['sortByLastUpdated'] !== 'false');
  }

  function init() {
    loadHorse();
    titleService.setTitle('Lists');
    timer();
    setUpFilters();

    watchFilters();
    search();
  }

  function watchFilters() {
    let timeoutPromise, delay = 600;
    $scope.$watch('vm.keyword', function(newKeyword, oldKeyword) {
      if (newKeyword !== oldKeyword) {
        $timeout.cancel(timeoutPromise);
        timeoutPromise = $timeout(function() {
          $location.search('keyword', newKeyword);
          replaceLastHistory();
          search();
        }, delay);
      }
    }, true);

    $scope.$watch('vm.filter', function(newFilter, oldFilter) {
      if (newFilter !== oldFilter) {
        pagesize = PAGE_SIZE;
        $location.search('filter', newFilter.name);
        replaceLastHistory();
        search();
      }
    }, true);

    $scope.$watch('vm.sortByLastUpdated', function(newVal, oldVal) {
      if (newVal !== oldVal) {
        $location.search('sortByLastUpdated', newVal);
        replaceLastHistory();
        search();
      }
    }, true);

    $scope.$watch('vm.categoryFilter', function(newFilter, oldFilter) {
      if (newFilter !== oldFilter) {
        pagesize = PAGE_SIZE;
        $location.search('categoryFilter', newFilter.name);
        replaceLastHistory();
        search();
      }
    }, true);
  }

  function loadMore() {
    if (vm.totalPages > 1) {
      pagesize += pagesize;
      search();
    }
  }

  function search() {
    timer();
    vm.status.busy = true;
    const queryParams: any = {
      horseId: $state.params.id,
      pagesize: pagesize,
      sort: vm.sortByLastUpdated ? 'updated' : 'dueOn',
      text: vm.keyword
    };

    if (vm.filter) {
      merge(queryParams, vm.filter.value);
    }

    if (vm.categoryFilter) {
      merge(queryParams, vm.categoryFilter.value);
    }

    if ($state.current.name !== 'lists') {
      queryParams.allHorses = true;
    }

    listRepository.search(queryParams).then(function(response) {
      vm.status.busy = false;
      vm.status.loaded = true;
      vm.lists = response.records;
      vm.totalPages = response.totalPages;
      vm.totalRecords = response.totalRecords;
    }).catch(function(error) {
      vm.status.error = true;
      responseHandler.processError(error);
    });
  }

  function timer() {
    vm.status.message = '';
    $timeout.cancel(timeout);
    timeout = $timeout(function() {
      vm.status.message = 'Oops! It is taking more time than expected.';
    }, 4000);
  }
}
