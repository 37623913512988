import {barnManagerModule} from '../../index.module';
import * as angular from 'angular';
import {sortBy} from 'lodash';

barnManagerModule.directive('barnSwitcher', barnSwitcher);

function barnSwitcher() {

  BarnSwitcherController.$inject = ['sessionService', 'userStorage', 'barnStorage', 'tenantRepository','$scope'];

  return {
    restricted: 'E',
    templateUrl: 'barn-switcher.html',
    controller: BarnSwitcherController,
    controllerAs: 'barn_switcher',
    bindToController: true
  };

  function BarnSwitcherController(
    sessionService,
    userStorage,
    barnStorage,
    tenantRepository,
    $scope
  ) {
    const vm = this, user = userStorage.getUser();

    vm.$onInit = function() {
      vm.showMenu = false;
      vm.barns = [];
      vm.initialBarn = vm.selectedBarn = barnStorage.getEnv();
      vm.setBarn = barn => {
        const previousBarn = { ...vm.selectedBarn };
        vm.selectedBarn = barn;
        if (vm.selectedBarn && vm.selectedBarn.tenant) {
          const barnToUpdate = { ...vm.selectedBarn, timeZone: vm.selectedBarn.tenant.timeZone };
          sessionService.updateEnv(barnToUpdate)
            .then(() => {
              vm.selectedBarn = barn;
            })
            .catch(() => {
              vm.selectedBarn = previousBarn;
            });
        } else {
          vm.selectedBarn = previousBarn;
        }
        $scope.$apply();
      };

      if (user.sysAdmin) {
        if (sessionService.isInitialized()) {
          //Get all barns
          vm.showMenu = true;
          tenantRepository.all().then(barns => {
            vm.barns = sortBy(barns, [(o) => o.name.toLocaleLowerCase()]).map(barn => {
              const te = angular.copy(barn.tenantEnvironments[0]);
              delete barn.tenantEnvironments;
              te.tenant = barn;
              return te;
            });
          });
        }
      }
    };
  }
}
