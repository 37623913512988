import {barnManagerModule} from '../index.module';
import * as angular from 'angular';
import {LocalDate, LocalTime, nativeJs} from '@js-joda/core';
import {filter, isEmpty, join, keys, map, sortBy, uniqBy} from 'lodash';

barnManagerModule
  .factory('Event', ['dateTimeUpgraded', 'EventTypes', 'TreatmentTypes', 'RepeatingFrequencies', 'WEEKDAYS', (
    dateTimeUpgraded,
    EventTypes,
    TreatmentTypes,
    RepeatingFrequencies,
    WEEKDAYS
  ) => {
    function Event(data) {
      angular.extend(this, angular.copy(data));
      if (!this.eventType) {
        this.type(EventTypes.NONE);
      } else {
        this.type(EventTypes[this.eventType]);
      }
    }

    Event.prototype = {
      enforceTitle: function() {
        if (isEmpty(this.title) || !this.customTitle) {
          this.title = this.generateTitle();
          this.customTitle = false;
        } else {
          this.customTitle = true;
        }
      },
      generateTitle: function() {
        switch (this.types) {
          case EventTypes.VET:
          case EventTypes.FARRIER:
          case EventTypes.DENTIST:
            return this.defaultTitle();
          case EventTypes.MEDICATION:
          case EventTypes.VACCINATION:
          case EventTypes.DEWORMING:
            return this.medTitle();
          case EventTypes.SHOW:
            if (isEmpty(this.showName)) {
              return this.typeAsString() + ' - ' + this.dateAsString();
            } else {
              return this.typeAsString() + ': ' + this.showName + ' - ' + this.dateAsString();
            }
          case EventTypes.LESSON:
            var startTime = !this.allDay ? LocalTime.from(nativeJs(this.startTimeWall)).format(dateTimeUpgraded.HOURS_FORMATTER) : 'All Day';
            return this.typeAsString() + ': ' + startTime + ' - ' + this.dateAsString();
          case EventTypes.OTHER:
            return this.typeAsString() + ' - ' + this.dateAsString();
          default:
            return '';
        }
      },
      dateAsString: function() {
        return LocalDate.from(nativeJs(this.date)).format(dateTimeUpgraded.DATE_FORMATTER);
      },
      typeAsString: function() {
        return this.types.name;
      },
      defaultTitle: function() {
        if (!this.vendor) {
          return this.typeAsString() + ' - ' + this.dateAsString();
        } else {
          return this.typeAsString() + ': ' + this.vendor.fullName() + ' - ' + this.dateAsString();
        }
      },
      medTitle: function() {
        if (this.treatments.length) {
          return this.typeAsString() + ': ' + this.treatments.join('/') + ' - ' + this.dateAsString();
        } else {
          return this.typeAsString() + ' - ' + this.dateAsString();
        }
      },
      hasTreatment: function() {
        return this.types.treatment != TreatmentTypes.NONE;
      },
      treatmentLabel: function() {
        return this.types.treatment;
      },
      vendorLabel: function() {
        return this.types.vendorRestriction.label;
      },
      hasVendor: function() {
        return !this.types.vendorRestriction.isNone;
      },
      canHaveTime: function() {
        return this.types !== EventTypes.SHOW && !this.allDay;
      },
      canHaveAssignments: function() {
        const eventTypes = [EventTypes.SHOW, EventTypes.LESSON, EventTypes.OTHER];
        return eventTypes.includes(this.types);
      },
      repeatToggled: function() {
        if (this.repeat) {
          this.repeatInterval = 1;
          this.repeatFrequency = RepeatingFrequencies.DAILY.internal;
        } else {
          this.repeatInterval = null;
          this.repeatFrequency = null;
        }
      },
      repeatingFrequency: function() {
        return RepeatingFrequencies[this.repeatFrequency];
      },

      repeatText: function() {
        var result = 'Repeats ';
        if (this.repeatInterval === 1) {
          result += this.repeatingFrequency().how;
        } else {
          result += ' every ' + this.repeatInterval + ' ' + this.repeatingFrequency().every;
        }
        if (this.repeatingFrequency() === RepeatingFrequencies.WEEKLY) {
          result += ' on ' + join(map(this.getWeekDays(), function(wd) {
            return wd.label;
          }), ', ');
        }
        if (this.endOnDate) {
          result += ' until ' + LocalDate.parse(this.endOnDate).format(dateTimeUpgraded.DATE_FORMATTER);
        }
        return result;
      },
      type: function(newType) {
        if (angular.isUndefined(newType)) {
          return this.types;
        }
        this.eventType = newType.value;
        this.types = newType;
        this.enforceVendorType();
        return this.types;
      },
      enforceVendorType: function() {
        if (!this.types.vendorRestriction.filter(this.vendor)) {
          this.vendor = null;
        }
      },
      isRepeating: function() {
        return this.repeat && this.eventType !== 'SHOW';
      },
      isAllDay: function() {
        return this.eventType !== 'SHOW' && this.allDay;
      },
      getWeekDays: function() {
        if (this.repeat && this.repeats.length > 0 && this.repeatFrequency === 'WEEKLY') {
          return sortBy(uniqBy(map(
            filter(this.repeats, function(r) {
              return keys(WEEKDAYS).indexOf(r.repeatWeekDay) >= 0;
            }),
            function(repeat) {
              return WEEKDAYS[repeat.repeatWeekDay];
            }
          ), 'value'), [ function(d) {
            return d.index;
          } ]);
        }
        return [];
      }
    };

    return Event;
  }]);
